<script
    lang="ts"
    setup
>
    type Props = {
        title: string
        contentClass?: string
    }

    const props = withDefaults(defineProps<Props>(), {
        contentClass: undefined,
    })

    const contentClass = computed<string>(() => {
        let result = 'relative flex items-center text-[20px] tablet:text-[40px] font-medium text-center mb-12'

        if (props.contentClass) {
            result += ' ' + props.contentClass
        }

        return result
    })
</script>

<template>
    <div class="relative overflow-y-auto flex justify-center w-full h-full bg-white">
        <Title>
            {{ props.title }}
        </Title>

        <div class="flex flex-col items-center">
            <div :class="contentClass">
                <div id="auth-page__title"></div>

                <slot name="title">
                    {{ props.title }}
                </slot>
            </div>

            <slot />
        </div>
    </div>
</template>
